import React, { useState } from "react"

import OTP from "./OTP"
import LengkapiData from "./LengkapiData"

const Register = ({ referal }) => {
  const [step, setStep] = useState("otp")

  const [uid, setUid] = useState("")

  if (step === "lengkapi-data") {
    return <LengkapiData referal={referal} uid={uid} />
  }

  return (
    <OTP
      nextStep={() => setStep("lengkapi-data")}
      setUid={uid => setUid(uid)}
    />
  )
}

export default Register
