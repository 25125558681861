import React, { useState } from "react"

import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import Register from "../components/register"

import SEO from "../components/seo"

const Reseller = ({ location }) => {
  const referal = location.pathname.split("/")

  return (
    <div>
      <SEO title="Daftar Reseller" />
      <Header />
      <Register referal={referal.length === 3 ? referal[2] : null} />
      <Footer />
    </div>
  )
}

export default Reseller
