import React, { useState, useRef, useEffect } from "react"

import firebase from "gatsby-plugin-firebase"
import isEmail from "validator/lib/isEmail"

import { navigate } from "gatsby"

let snap = null

if (typeof window !== `undefined`) {
  snap = window.snap
}

function getAkunType(referral) {
  if (referral.ref_agen) {
    return "reseller"
  } else if (referral.ref_distributor) {
    return "agen"
  } else if (referral.ref_mentorbisnis) {
    return "distributor"
  } else {
    return "reseller"
  }
}

function LengkapiData({ uid, referal }) {
  const [form, setForm] = useState({
    nama: "",
    email: "",
    kode_referral: "",
    password: "",
    ulangi_password: "",
  })

  const [errors, setErrors] = useState({
    nama: "",
    email: "",
    kode_referral: "",
    password: "",
    ulangi_password: "",
  })

  const dataRefferal = useRef({})

  const [loading, setLoading] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target

    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: "" })
  }

  const region =
    process.env.GATSBY_FIREBASE_PROD === "YES"
      ? "asia-southeast2"
      : "us-central1"

  const validate = async () => {
    const functions = firebase.app().functions(region)

    let newErrors = {}

    if (!form.nama) {
      newErrors.nama = "Nama wajib diisi"
    }

    if (form.email && !isEmail(form.email)) {
      newErrors.email = "Email tidak valid"
    } else if (!form.email) {
      newErrors.email = "Email wajib diisi"
    }

    if (!form.kode_referral) {
      newErrors.kode_referral = "Kode referral wajib diisi"
    } else {
      const validateReferral = functions.httpsCallable("validateReferral")

      const referral = await validateReferral({
        kode: form.kode_referral,
      })

      const { valid, message, ...restRef } = referral.data

      if (!valid) {
        newErrors.kode_referral = message
      } else {
        dataRefferal.current = restRef
      }
    }

    if (!form.password) {
      newErrors.password = "Password wajib diisi"
    } else if (form.password.length < 8) {
      newErrors.password = "Password minimal 8 karakter"
    }

    if (!form.ulangi_password) {
      newErrors.ulangi_password = "Ulangi Password wajib diisi"
    } else if (form.ulangi_password !== form.password) {
      newErrors.ulangi_password = "Ulangi Password tidak sama dengan Password"
    }

    return newErrors
  }

  const handleSubmit = async e => {
    setLoading(true)

    e.preventDefault()

    const functions = firebase.app().functions(region)

    const firestore = firebase.firestore()

    const findErrors = await validate()

    if (Object.values(findErrors).some(message => message !== "")) {
      setErrors(findErrors)
      setLoading(false)
    } else {
      try {
        const { password, ulangi_password, ...restForm } = form

        // step-1 set password
        const setPassword = functions.httpsCallable("setPassword")
        await setPassword({
          password: password,
          ulangi_password: ulangi_password,
        })

        const tipe_akun = getAkunType(dataRefferal.current)
        // step-2 generate transaction token untuk pembayaran biaya registrasi
        const generateRegisterTransactionToken = functions.httpsCallable(
          "generateRegisterTransactionToken"
        )
        const midtrans_registrasi = await generateRegisterTransactionToken({
          type: tipe_akun,
        })

        // step-3 simpan data profil ke doc users/{userId}
        await firestore.doc(`users/${uid}`).set(
          {
            ...restForm,
            tipe_akun,
            midtrans_registrasi: midtrans_registrasi.data,
            ...dataRefferal.current,
          },
          { merge: true }
        )

        // step-4 snap pay midtrans
        snap.pay(midtrans_registrasi?.data?.token, {
          onSuccess: function () {
            console.log("success")
          },
          onPending: async function (result) {
            console.log("pending")

            await firestore.doc(`users/${uid}`).set(
              {
                midtrans_registrasi: {
                  ...midtrans_registrasi.data,
                  ...result,
                },
              },
              { merge: true }
            )

            navigate(`/finish`)
          },
          onError: function () {
            console.log("error")
          },
          onClose: function () {
            console.log(
              "customer closed the popup without finishing the payment"
            )
          },
        })

        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert("Terjadi Kesalahan", "Silahkan Coba Lagi Nanti")
      }
    }
  }

  useEffect(() => {
    if (referal !== null) {
      setForm({ ...form, kode_referral: referal })
    }
  }, [])

  return (
    <div className="mx-auto" style={{ width: 300 }}>
      <form onSubmit={handleSubmit}>
        <fieldset disabled={loading}>
          <div className="form-group">
            <label>Nama</label>
            <input
              name="nama"
              type="text"
              className={`form-control ${errors.nama ? "is-invalid" : ""}`}
              onChange={handleChange}
              value={form.nama}
              autoComplete="off"
            />
            <small className="invalid-feedback">{errors.nama}</small>
          </div>
          <div className="form-group">
            <label>Email Opsional</label>
            <input
              name="email"
              type="text"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              onChange={handleChange}
              value={form.email}
              autoComplete="off"
            />
            <small className="invalid-feedback">{errors.email}</small>
          </div>
          <div className="form-group">
            <label>Kode Referal</label>
            <input
              name="kode_referral"
              type="text"
              className={`form-control ${
                errors.kode_referral ? "is-invalid" : ""
              }`}
              onChange={handleChange}
              value={form.kode_referral}
              autoComplete="off"
            />
            <small className="invalid-feedback">{errors.kode_referral}</small>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              name="password"
              type="password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              onChange={handleChange}
              value={form.password}
            />
            <small className="invalid-feedback">{errors.password}</small>
          </div>
          <div className="form-group">
            <label>Ulangi Password</label>
            <input
              name="ulangi_password"
              type="password"
              className={`form-control ${
                errors.ulangi_password ? "is-invalid" : ""
              }`}
              onChange={handleChange}
              value={form.ulangi_password}
            />
            <small className="invalid-feedback">{errors.ulangi_password}</small>
          </div>
        </fieldset>
        <button
          type="submit"
          className={`btn-${
            !loading ? "primary" : "secondary "
          } btn-sm btn-block`}
          disabled={loading}
        >
          {loading ? "Loading..." : "Kirim"}
        </button>
      </form>
    </div>
  )
}

export default LengkapiData
