import React, { useState } from "react"

import InputMask from "react-input-mask"

import isMobilePhone from "validator/lib/isMobilePhone"
import isNumeric from "validator/lib/isNumeric"

import firebase from "gatsby-plugin-firebase"

const OTP = ({ nextStep, setUid }) => {
  const [phone, setPhone] = useState("")

  const [error, setError] = useState(null)

  const [sent, setSent] = useState(false)

  const [otp, setOtp] = useState(new Array(6).fill(""))

  const [loading, setLoading] = useState(false)

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))])

    setError("")

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus()
    }
  }

  const region =
    process.env.GATSBY_FIREBASE_PROD === "YES"
      ? "asia-southeast2"
      : "us-central1"

  const requestOTP = async e => {
    setLoading(true)
    try {
      e.preventDefault()

      const functions = firebase.app().functions(region)

      const no_hp = phone.replaceAll(/-|_/gm, "")

      if (!phone) {
        throw Error("No WhatsApp wajib diisi")
      } else if (!isMobilePhone(no_hp, "id-ID")) {
        throw Error("No WhatsApp tidak valid")
      }

      const callSendOTP = functions.httpsCallable("sendOTP")

      await callSendOTP({ no_hp: no_hp })

      setSent(true)

      setLoading(false)
    } catch (e) {
      setError(e.message)

      setLoading(false)
    }
  }

  const verifyOTP = async e => {
    setLoading(true)
    try {
      e.preventDefault()

      const functions = firebase.app().functions(region)

      const auth = firebase.auth()

      const code = otp.join("")

      const no_hp = phone.replaceAll(/-|_/gm, "")

      if (!code) {
        throw Error("Kode OTP wajib diisi")
      } else if (code.length < 6 || code.length > 6 || !isNumeric(code)) {
        throw Error("Kode OTP tidak valid")
      }

      const callVerifyOTP = functions.httpsCallable("verifyOTP")

      const response = await callVerifyOTP({ no_hp: no_hp, otp: code })

      await auth.signInWithCustomToken(response?.data?.custom_token ?? "token")

      setUid(response?.data?.uid)

      setLoading(false)

      nextStep()
    } catch (e) {
      setError(e.message)

      setLoading(false)
    }
  }

  return (
    <div className="mx-auto" style={{ width: 300 }}>
      {sent ? (
        <div className="col text-center">
          <p>Selamat Datang !</p>
          <p>Masukkan 6 digit kode yang telah dikirimkan</p>
          <p>ke nomor {phone.replace("_", "")}</p>
        </div>
      ) : (
        <div className="col text-center">
          <p>Verifikasi No. Telepon</p>
          <p>Masukkan nomor telepon kamu</p>
        </div>
      )}
      <form onSubmit={sent ? verifyOTP : requestOTP}>
        {sent ? (
          <>
            <div className="d-flex justify-content-around">
              {otp.map((data, index) => {
                return (
                  <input
                    className={`form-control text-center ${
                      index === 5 ? "" : "mr-2"
                    }`}
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={e => handleChange(e.target, index)}
                    onFocus={e => e.target.select()}
                    autoComplete="off"
                    disabled={loading}
                  />
                )
              })}
            </div>
            {error !== null && <small className="text-danger">{error}</small>}
            <button
              type="submit"
              className={`btn-${
                !loading ? "primary" : "secondary "
              } btn-sm btn-block mt-3`}
              disabled={loading}
            >
              {loading ? "Loading..." : "Verifikasi"}
            </button>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>Nomor WhatsApp</label>
              <InputMask
                mask="+62-999-9999-9999"
                className="form-control"
                placeholder="+62-852-1234-5678"
                minLength={10}
                onChange={e => {
                  setPhone(e.target.value)
                  setError("")
                }}
                value={phone}
                disabled={loading}
              />
              {error !== null && <small className="text-danger">{error}</small>}
            </div>
            <button
              type="submit"
              className={`btn-${
                !loading ? "primary" : "secondary "
              } btn-sm btn-block`}
              disabled={loading}
            >
              {loading ? "Loading..." : "Kirim OTP"}
            </button>
          </>
        )}
      </form>
    </div>
  )
}

export default OTP
